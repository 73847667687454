
import { timeBetween } from '~/utility/date/relative'
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'

@Component
export default class TimeAgo extends Vue {
  @Prop({ required: true })
    date: string

  ago: { unit: string; value: number } | null = null
  intervalId: ReturnType<typeof setInterval> | null = null

  created() {
    this.ago = timeBetween(this.date)
  }

  mounted() {
    this.intervalId = setInterval(() => {
      this.ago = timeBetween(this.date)
    }, this.getIntervalDuration())
  }

  beforeUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
  }

  getIntervalDuration(): number {
    if (!this.ago) {
      return 1000 // Default to 1 second if ago is not yet set
    }
    switch (this.ago.unit) {
      case 'second':
        return 1000 // 1 second
      case 'minute':
        return 60000 // 1 minute
      case 'hour':
        return 3600000 // 1 hour
      case 'day':
        return 86400000 // 1 day
      default:
        return 1000 // Default to 1 second for other units
    }
  }
}
