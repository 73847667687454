
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'

@Component({})
export default class PuRatingBubble extends Vue {
  defaultValue = this.$t('No rating')

  @Prop({ default: 0 })
    rating!: number

  @Prop({ default: 0 })
    rateCount!: number

  @Prop({ default: false })
    forceVisibility!: boolean

  get ratingIsVisible() {
    return this.rateCount >= 3 || this.forceVisibility === true
  }

  get ratingFormatted() {
    const rating = this.rating || 0
    // rating.toFixed returns a string - for example '10.0'
    // parseFloat will convert that string into a number dropping any trailing zeros
    return parseFloat(rating.toFixed(1))
  }
}
