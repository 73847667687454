import { render, staticRenderFns } from "./BaseCheckbox.vue?vue&type=template&id=70ad41ed&scoped=true"
import script from "./BaseCheckbox.vue?vue&type=script&lang=ts"
export * from "./BaseCheckbox.vue?vue&type=script&lang=ts"
import style0 from "./BaseCheckbox.vue?vue&type=style&index=0&id=70ad41ed&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70ad41ed",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InputErrorMessage: require('/app/components/base/InputErrorMessage.vue').default})
