
import { NuxtError } from '@nuxt/types'
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'

@Component
export default class Error500 extends Vue {
  @Prop({ type: Object, required: true }) readonly error!: NuxtError

  @Prop({ required: true }) readonly contactUsLink: string
}
