import { render, staticRenderFns } from "./DatesWizardBodyHeaderRange.vue?vue&type=template&id=785a1e30&scoped=true"
import script from "./DatesWizardBodyHeaderRange.vue?vue&type=script&lang=ts"
export * from "./DatesWizardBodyHeaderRange.vue?vue&type=script&lang=ts"
import style0 from "./DatesWizardBodyHeaderRange.vue?vue&type=style&index=0&id=785a1e30&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "785a1e30",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Translate: require('/app/components/Translate.vue').default,PuUtcDate: require('/app/components/ui/PuUtcDate.vue').default,PuDate: require('/app/components/ui/PuDate.vue').default})
