import { Options } from 'flatpickr/dist/types/options'
import { reportError } from '~/utility/reportError'

const DATE_PICKER_CONFIG: Options = {
  // https://github.com/ankurk91/vue-flatpickr-component
  // https://flatpickr.js.org/options/
  // https://flatpickr.js.org/formatting/
  allowInput: false,
  altFormat: 'D j M',
  altInput: true,
  animate: true,
  closeOnSelect: false,
  dateFormat: 'Y-m-d',
  inline: true,
  locale: 'en',
  minDate: 'today',
  mode: 'range',
  monthSelectorType: 'static',
  nextArrow: `<img src='${require('@/assets/images/forward-rounded-dark-blue.svg')}'>`,
  prevArrow: `<img src='${require('@/assets/images/forward-rounded-dark-blue.svg')}'>`,
}

export function getDatePickerConfig(locale: string): Options {
  const config = { ...DATE_PICKER_CONFIG }
  try {
    const currentLanguageCode = locale.split('-')[0]
    config.locale = {
      ...loadLocalePackage(currentLanguageCode),
      firstDayOfWeek: 1,
    }
  } catch (err) {
    reportError(err as Error)
    config.locale = 'en'
  }
  return config
}

function loadLocalePackage(currentLanguageCode: string) {
  if (currentLanguageCode === 'en') {
    return {}
  }
  const langCode = langExceptions[currentLanguageCode] || currentLanguageCode
  const languagePackage = require(`/node_modules/flatpickr/dist/l10n/${langCode}.js`)
  return languagePackage.default[langCode]
}

const langExceptions = {
  el: 'gr',
  ca: 'cat',
}
