
import { Component, Mixins, Prop, Watch } from '~/utility/pu-class-decorator'
import ValidationProviderMixin from '~/mixins/ValidationProviderMixin'
import { BaseInputProps } from './BaseInputProps'

@Component({
  inheritAttrs: false,
})
export default class BaseInput
  extends Mixins(ValidationProviderMixin)
  implements BaseInputProps
{
  @Prop({ type: [String, Boolean] })
    value!: string

  @Prop()
    name!: string

  @Prop({ default: 'text' })
    type?: string

  @Prop()
    disabled!: boolean

  @Prop({ default: () => [] })
    validationErrors: string[]

  @Prop({ default: false })
    required?: boolean | string

  @Prop()
    validationRules!: object

  // this is a function that if set will be called to parse the value
  // before it is validated
  @Prop()
    validationParser?: (value: string) => string

  contentValue = ''

  @Watch('value')
  onValueChange(newVal: string) {
    this.contentValue = newVal
  }

  mounted() {
    this.contentValue = this.value
  }

  get isRequired() {
    return !!this.required || this.required === ''
  }

  get labelText() {
    return this.$slots.label?.length ? this.$slots.label[0].text : ''
  }

  get listeners() {
    const { input, ...listeners } = this.$listeners
    return listeners
  }

  hasError(errors?: string[]) {
    return errors
      ? (this.validationErrors && this.validationErrors.length > 0) ||
          errors.length > 0
      : false
  }

  doValidate(validate: (v: string) => any, event: any) {
    // @blur="validate((validationParser ? validationParser : (v: string) => v)(($event.target as HTMLInputElement).value))"
    const target = event.target as HTMLInputElement
    const parser = this.validationParser
      ? this.validationParser
      : (v: string) => v
    const value = parser(target.value)
    validate(value)
  }

  firstFailedRule(failedRules) {
    return Object.keys(failedRules)[0] || undefined
  }

  handleInput(event: Event) {
    const target = event.target as HTMLInputElement
    this.contentValue = target.value
    this.$emit('input', target.value)
  }
}
