
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'
import { ArriveDepartIso } from '../../../apps/search/types'

@Component
export default class DatesWizardSummary extends Vue {
  @Prop({ required: false })
    dates: ArriveDepartIso

  @Prop({ required: false, type: Boolean, default: false })
    required: boolean

  @Prop({ required: false, type: Boolean, default: false })
    highlightErrors: boolean

  get hasDates() {
    return this.dates && this.dates.arrive && this.dates.depart
  }
}
