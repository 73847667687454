
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'

@Component
export default class Translate extends Vue {
  @Prop()
  tt: Record<string, any>

  @Prop()
  tag: string

  translationKey: string | null = null

  beforeUpdate() {
    // this hooks seems to run when $slots are updated by the v-if
    // and runs before the rerender
    // so update the content before
    this.translationKey = this.getTranslationKey()
  }

  // note this is not a computed getter since $slots aren't reactive
  getTranslationKey() {
    return this.$slots.default && this.$slots.default[0].text
      ? this.trimKey(this.$slots.default[0].text)
      : null
  }

  get translation() {
    if (!this.translationKey) {
      this.translationKey = this.getTranslationKey()
    }
    if (this.translationKey) {
      try {
        return this.$t(this.translationKey, this.tt)
      } catch (e) {
        // don't want user to see an error if trans broken
        // but this at least gets it to sentry
        console.error(e)
      }
    }
    return this.translationKey
  }

  trimKey(key: string): string {
    return key.replace(/\s\s+/g, ' ').trim()
  }
}
