import { render, staticRenderFns } from "./error500.vue?vue&type=template&id=b8ef929e"
import script from "./error500.vue?vue&type=script&lang=ts"
export * from "./error500.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Translate: require('/app/components/Translate.vue').default,PuLink: require('/app/apps/pu-links/pu-link/PuLink.vue').default})
