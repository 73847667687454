
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'

@Component
export default class BaseSubmitButton extends Vue {
  @Prop({ default: false })
    submitting: boolean

  @Prop()
    disabled: boolean

  @Prop({ required: false })
    cssClass?: string

  callback(e: any) {
    this.$emit('click', e)
  }

  get listeners() {
    const { click, ...listeners } = this.$listeners
    return listeners
  }
}
