
import {
  DayElement as FlatPickrDayElement,
  Instance as FlatPickrInstance,
} from 'flatpickr/dist/types/instance'
import type { DateRangePicker } from 'vue-flatpickr-component'
import { puNumber } from '~/lang/formatters/puNumber'
import { useCurrencyStore } from '~/stores/useCurrencyStore'
import { DateRange } from '~/utility/date/DateRange'
import { IsoDateFormatter } from '~/utility/date/IsoDateFormatter'
import { addDays } from '~/utility/date/relative'
import { SimpleMoney, priceToMoneyType } from '~/utility/money'
import { Component, Prop, Vue, Watch } from '~/utility/pu-class-decorator'
import { CampsiteCategory } from '~/utility/types/CampsiteCategory'
import { Nullable } from '~/utility/types/Nullable'
import { AvailabilityResults } from '../../../apps/search-wizard/availability/schemas'
import { OpeningDatesRange } from '../../../apps/search-wizard/types'
import { ArriveDepartIso } from '../../../apps/search/types'
import { getDatePickerConfig } from './getDatePickerConfig'
import { ChargetypeCalendarDay } from './types'

@Component<DatesWizardBody>({
  components: {
    'date-range-picker': () =>
      import(
        /* webpackChunkName: "vue-flatpickr", webpackMode: "lazy" */ 'vue-flatpickr-component'
      ),
  },
  head() {
    return {
      bodyAttrs: {
        class: this.$isDesktop ? '' : 'noscroll',
      },
    }
  },
  setup() {
    const currencyStore = useCurrencyStore()
    return {
      currencyStore,
    }
  },
})
export default class DatesWizardBody extends Vue {
  currencyStore: ReturnType<typeof useCurrencyStore>

  @Prop({ required: true })
    dates: Nullable<ArriveDepartIso>

  @Prop({ required: false, default: true })
    showHeaderRange: boolean

  @Prop({ required: false })
    openingDates?: OpeningDatesRange[]

  @Prop()
    calendarStartDate: string

  @Prop()
    availability: AvailabilityResults

  get dateAvailability() {
    return this.$route.params.slug ? this.availability : []
  }

  @Prop()
    chargetypeCalendarData: { [key: string]: ChargetypeCalendarDay }

  @Prop()
    categoryDetails: CampsiteCategory[]

  @Prop()
    selectedDatesPrice: SimpleMoney

  private currentMonth = 0
  private currentYear = 0
  datePickerModel: string | Date | null | Array<string | Date | null> = null
  private setPickerState(
    start: string | Date | null = null,
    end: string | Date | null = null,
  ) {
    this.datePickerModel = getDatePickerModel(start, end)
  }
  showingArrivalDays = true
  selectionTagClass = 'date-picker__selection-key-desc'

  beforeMount() {
    if (this.start) {
      this.setDatesForPicker(this.start)
    }
    if (this.hasOpeningDates && !this.start) {
      this.setCurrentMonthToFirstOpeningDate()
    }
  }

  get headerRangeDates() {
    if (!this.dates.depart) {
      return {
        arrive: this.dates.arrive,
        depart: this.dates.arrive ? addDays(1, this.dates.arrive) : null,
      }
    }
    return this.dates
  }

  @Watch('dates')
  onDatesChanged(
    dates: Nullable<ArriveDepartIso>,
    prevDates: Nullable<ArriveDepartIso>,
  ) {
    if (
      dates?.arrive !== prevDates?.arrive ||
      dates?.depart !== prevDates?.depart
    ) {
      const arrive = dates.arrive ? dates.arrive : null
      const depart = dates.depart ? dates.depart : null
      this.setPickerState(arrive, depart)
    }
  }

  @Watch('chargetypeCalendarData')
  @Watch('convertedSelectedDatesPrice')
  chargetypeCalendarDataChange() {
    (this.$refs['date-picker'] as DateRangePicker)?.fp.redraw()
  }

  private setCurrentMonthToFirstOpeningDate() {
    const start = IsoDateFormatter.fromIsoDate(this.closestOpeningDate.start)
    this.currentMonth = start.getMonth()
    this.currentYear = start.getFullYear()
    this.$emit('monthChanged', this.currentMonth)
    this.$emit('yearChanged', this.currentYear)
  }

  private setDatesForPicker(start: Date) {
    this.currentMonth = start.getMonth()
    this.currentYear = start.getFullYear()
    this.setPickerState(start, this.end)
    this.submitDates(start, this.end)
  }

  private get availableDates() {
    return this.dateAvailability?.map((day) => day.date)
  }

  get start(): Date | null {
    if (!this.dates.arrive) {
      return null
    }

    // Flatpickr will use a local date. When we create a date using ISO format
    // with only YYYY-MM-DD it will be UTC with a time of 00:00:00. In timezones
    // with a negative time difference this means date get displayed as the day
    // before arrive date.
    const dateUTC = IsoDateFormatter.fromIsoDate(this.dates.arrive)
    const dateLocal = new Date(
      Date.UTC(
        dateUTC.getUTCFullYear(),
        dateUTC.getUTCMonth(),
        dateUTC.getUTCDate(),
      ),
    )
    return dateLocal
  }

  get end() {
    if (!this.dates.depart) {
      return null
    }

    // See comment above RE timezones.
    const dateUTC = IsoDateFormatter.fromIsoDate(this.dates.depart)
    const dateLocal = new Date(
      Date.UTC(
        dateUTC.getUTCFullYear(),
        dateUTC.getUTCMonth(),
        dateUTC.getUTCDate(),
      ),
    )
    return dateLocal
  }

  convertedSelectedDatesPrice: number | null = null

  @Watch('selectedDatesPrice', { immediate: true, deep: true })
  selectedDatesPriceUpdated() {
    if (this.selectedDatesPrice) {
      this.convertedSelectedDatesPrice = this.currencyStore
        .convert(
          priceToMoneyType(this.selectedDatesPrice),
          this.$i18n.current.currency,
        )
        .getAmount()
    } else {
      this.convertedSelectedDatesPrice = null
    }
  }

  onDatePickerDatesChange(
    dates: Date[],
    _: string,
    datepicker: FlatPickrInstance,
  ) {
    this.showingArrivalDays = dates.length !== 1
    const start = dates[0] || null
    let end = dates[1] || null
    const isSameDay =
      start &&
      end &&
      start.getDate() === end.getDate() &&
      start.getMonth() === end.getMonth() &&
      start.getFullYear() === end.getFullYear()
    if (isSameDay && start) {
      end = this.getDateModifiedByStep(start)
      datepicker.setDate([start, end])
    }
    if (start && end) {
      this.$emit('changeRange', [start, end])
    } else if (start) {
      this.$emit('changeRange', [start])
    }
    if (!this.dateAvailability && !end) {
      this.addStartRangeClass(datepicker?.selectedDateElem)
    }
    this.submitDates(start, end)

    if (!start && !end) {
      datepicker.changeYear(this.currentYear)
      datepicker.changeMonth(this.currentMonth, false)
    }
  }

  onDatePickerReady(
    _dates: Date[],
    _date: string,
    datepicker: FlatPickrInstance,
  ) {
    const setCalendarOn = this.getMonthAndYearToOpenCalendarOn()
    if (setCalendarOn) {
      this.currentYear = setCalendarOn.year
      this.currentMonth = setCalendarOn.month
      datepicker.changeYear(setCalendarOn.year)
      datepicker.changeMonth(setCalendarOn.month, false)
    } else {
      this.currentYear = datepicker.currentYear
      this.currentMonth = datepicker.currentMonth
    }
    this.$emit('monthChanged', this.currentMonth)
    this.$emit('yearChanged', this.currentYear)
  }

  getMonthAndYearToOpenCalendarOn() {
    if (this.calendarStartDate && !this.start) {
      const startDate = new Date(this.calendarStartDate)
      return { year: startDate.getFullYear(), month: startDate.getUTCMonth() }
    } else if (this.hasOpeningDates && !this.start) {
      return { year: this.currentYear, month: this.currentMonth }
    }
    return undefined
  }

  onDatePickerMonthChange(
    _dates: Date[],
    _date: string,
    datepicker: FlatPickrInstance,
  ) {
    this.currentMonth = datepicker.currentMonth
    this.currentYear = datepicker.currentYear
    this.$emit('monthChanged', this.currentMonth)
    this.$emit('yearChanged', this.currentYear)
  }

  onDatePickerYearChange(
    _dates: Date[],
    _date: string,
    datepicker: FlatPickrInstance,
  ) {
    this.currentYear = datepicker.currentYear
    this.$emit('yearChanged', this.currentYear)
  }

  onDatePickerChange(
    dates: Date[],
    _date: string,
    _datepicker: FlatPickrInstance,
  ) {
    this.$emit('monthChanged', this.currentMonth)
    this.$emit('yearChanged', this.currentYear)
    this.$emit('datesChanged', dates)
  }

  onDatePickerDayCreate(
    dates: Date[],
    _date: string,
    _datepicker: FlatPickrInstance,
    day: FlatPickrDayElement,
  ) {
    if (this.availableDates && this.$route.params.slug) {
      this.availableDatesDayRender(dates, day)
    }
  }

  clearDates() {
    this.$emit('clearDates')
    this.submitDates(null, null)
  }

  private submitDates(start: Date | null = null, end: Date | null = null) {
    const arrive = start ? IsoDateFormatter.toIsoDate(start) : null
    const depart = end ? IsoDateFormatter.toIsoDate(end) : null
    const dateRange = { arrive, depart }
    this.$emit('changeDates', dateRange)
    this.$emit('yearChanged', this.currentYear)
    this.$emit('datesChanged', [start, end])
  }

  get datePickerConfig() {
    const config = getDatePickerConfig(this.$i18n.locale)
    config.onReady = this.onDatePickerReady
    config.onDayCreate = this.onDatePickerDayCreate
    config.onChange = this.onDatePickerChange
    return config
  }

  private availableDatesDayRender(
    selectedDates: Date[],
    day: FlatPickrDayElement,
  ) {
    const isoDate = IsoDateFormatter.toIsoDate(day.dateObj)
    if (selectedDates.length === 1) {
      const startDayAvailability = this.dateAvailability?.find(
        (d) => d.date === IsoDateFormatter.toIsoDate(selectedDates[0]),
      )
      this.addAvailabilityClass(
        startDayAvailability?.bookable_dates.includes(isoDate) ||
          startDayAvailability?.date === isoDate,
        day,
      )
    } else {
      this.addAvailabilityClass(this.availableDates?.includes(isoDate), day)
    }

    if (
      isoDate === this.dates.arrive &&
      selectedDates.length === 2 &&
      isoDate === IsoDateFormatter.toIsoDate(selectedDates[0])
    ) {
      this.addStayPriceToDay(day)
    } else if (this.chargetypeCalendarData) {
      this.addChargetypeToDay(isoDate, day)
    }
  }

  private addAvailabilityClass(available: boolean, day: FlatPickrDayElement) {
    day.classList.add(available ? 'date-available' : 'date-unavailable')
  }

  private addStartRangeClass(day?: FlatPickrDayElement) {
    day?.classList.add('startRange')
  }

  private addChargetypeToDay(isoDate: string, day: FlatPickrDayElement) {
    const calDayChargetype = this.chargetypeCalendarData[isoDate]
    if (!calDayChargetype || !calDayChargetype.priceFx) {
      return
    }

    const usersPrice =
      calDayChargetype.priceFx[this.$i18n.current.currency] / 100
    const digitsAmount = calDayChargetype.priceFx
      ? this.getMinFractionDigits(usersPrice)
      : 0
    const priceObject = puNumber(
      usersPrice,
      this.$i18n.current.currency,
      `minimumFractionDigits:${digitsAmount} maximumFractionDigits:${digitsAmount}`,
    )

    const nights = calDayChargetype.minDaysViolated
      ? String(calDayChargetype.minDays)
      : String(calDayChargetype.nights)

    this.addPriceToDay(day, priceObject, nights)
  }

  private addStayPriceToDay(day: FlatPickrDayElement) {
    if (!this.convertedSelectedDatesPrice) {
      return
    }
    const usersPrice = this.convertedSelectedDatesPrice / 100
    const digitsAmount = this.getMinFractionDigits(usersPrice)

    const stayPrice = puNumber(
      usersPrice,
      this.$i18n.current.currency,
      `minimumFractionDigits:${digitsAmount} maximumFractionDigits:${digitsAmount}`,
    )

    this.addPriceToDay(day, stayPrice, String(this.dateRangeNights))
  }

  private addPriceToDay(
    day: FlatPickrDayElement,
    price: string,
    nights: string,
  ) {
    const chargetypeElements: (HTMLElement | string)[] = []
    const dom = day.innerHTML
    chargetypeElements.push(dom)
    chargetypeElements.push(this.createDayPricingEl(price, 'quote-price'))
    chargetypeElements.push(this.createDayPricingEl(nights, 'quote-nights'))

    day.innerHTML = ''
    day.append(...chargetypeElements)
  }

  private createDayPricingEl(value: string, elClass: string) {
    const pricingEl = document.createElement('span')
    pricingEl.classList.add(elClass)
    pricingEl.append(value)
    return pricingEl
  }

  get dateRangeNights() {
    const { arrive, depart } = this.dates
    return new DateRange(arrive!, depart!).nights
  }

  private get hasOpeningDates() {
    if (!this.$route.params.slug) {
      return false
    }
    return this.openingDates?.length
  }

  private get closestOpeningDate(): DateRange {
    const start = this.findClosestOpeningDate()
    const end = this.getDateModifiedByStep(start)
    return new DateRange(
      IsoDateFormatter.toIsoDate(start),
      IsoDateFormatter.toIsoDate(end),
    )
  }

  private findClosestOpeningDate(): Date {
    const today = new Date()
    if (this.openingDates) {
      for (const openingDate of this.openingDates) {
        const from = new Date(openingDate.from)
        const to = new Date(openingDate.to)
        if (from <= today && today <= to) {
          return today
        } else if (from > today) {
          return from
        }
      }
    }
    return new Date()
  }

  private getDateModifiedByStep(dateToModify: Date, step: number = 2): Date {
    const date = new Date(dateToModify)
    date.setDate(dateToModify.getDate() + step)
    return date
  }

  private getMinFractionDigits(price: number) {
    return price - Math.floor(price) !== 0 ? 2 : 0
  }
}

function getDatePickerModel(
  start: string | Date | null,
  end: string | Date | null = null,
) {
  if (!start) return []
  return [start, end]
}
