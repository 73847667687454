
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'

@Component({})
export default class BaseButton extends Vue {
  @Prop({ default: 'button' })
  type!: string

  @Prop({ required: false })
  cssClass!: object

  @Prop()
  disabled!: boolean

  callback(e: any) {
    this.$emit('click', e)
  }

  get listeners() {
    const { click, ...listeners } = this.$listeners
    return listeners
  }
}
