
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'
import { CampsiteCategoriesUpdaterJson } from '~/apps/categories/presenter-json/CampsiteCategoriesUpdaterJson'
import { arrayByKey } from '~/utility/array'

interface CategoryIcon {
  id: string
  slug: string
  name: string
  isPositive?: boolean
}

@Component
export default class PuCategoryIcons extends Vue {
  @Prop({ required: true })
    categories: CategoryIcon[]

  getSrc(category: CategoryIcon) {
    try {
      return require(`@/assets/images/category-icons/${category.slug}${
        this.isPositive(category) ? '' : '-no'
      }.svg`)
    } catch (err) {
      this.$sentry.captureException(err)
      return ''
    }
  }

  getAltText(category: CategoryIcon) {
    return this.isPositive(category)
      ? category.name
      : this.$t('{categoryName} - not allowed', { categoryName: category.name })
  }

  bySlugMap = new CampsiteCategoriesUpdaterJson(
    this.$i18n.locale || 'en-gb',
  ).getPresenter().bySlugMap

  get categoriesSorted() {
    const categoriesMap = arrayByKey(this.categories, 'slug')
    const positiveCategories: CategoryIcon[] = []
    const negativeCategories: CategoryIcon[] = []
    for (const c of Object.values(this.bySlugMap)) {
      const category = categoriesMap[c.slug]
      if (!category) continue
      category.name = c.pretty_name
      this.isPositive(category)
        ? positiveCategories.push(category)
        : negativeCategories.push(category)
    }
    return positiveCategories.concat(negativeCategories)
  }

  private isPositive(category: CategoryIcon) {
    return category.isPositive !== false
  }
}
