
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'
import { ArriveDepartIso } from '../../../apps/search/types'

@Component
export default class DatesWizardSummaryDesktop extends Vue {
  @Prop({ required: false })
    dates: ArriveDepartIso

  get hasDates() {
    return this.dates && this.dates.arrive && this.dates.depart
  }

  get isSameMonth() {
    const arrive = new Date(this.dates.arrive)
    const depart = new Date(this.dates.depart)
    return arrive.getMonth() === depart.getMonth()
  }
}
