
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'
import { CampsiteCategory } from '~/utility/types/CampsiteCategory'

@Component
export default class DatesWizardKeys extends Vue {
  @Prop()
    categories: CampsiteCategory[]

  @Prop()
    shouldShowArrivalDays: boolean

  @Prop()
    selectionTagClass: string

  getCategorySvg(slug: string) {
    return require(`@/assets/images/category-icons/${slug}.svg`)
  }
}
