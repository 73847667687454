
import { Component, Prop } from '~/utility/pu-class-decorator'
import { BaseCheckboxProps } from './BaseCheckboxProps'
import BaseInput from './BaseInput.vue'

@Component
export default class BaseCheckbox
  extends BaseInput
  implements BaseCheckboxProps
{
  @Prop({ default: false })
    slideToggle!: boolean

  validateAndChange(validate: any, event: any) {
    const value = event.target.checked
    validate(event)
    this.$emit('input', value)
    this.$emit('change', value)
  }

  get listeners() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { change, input, ...listeners } = this.$listeners
    return listeners
  }
}
