// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/ratings-bubble-tail.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../../assets/images/ratings-bubble-tail-alt.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rating-bubble[data-v-4b710281]{align-items:center;background:#3a82a6;border-radius:100%;color:#fff;display:inline-flex;font-size:.9em;justify-content:center;line-height:1;min-height:1.6em;min-width:2.2em;position:relative;-webkit-user-select:none;user-select:none;z-index:1}.rating-value[data-v-4b710281]{color:#fff;font-weight:700;letter-spacing:-.035em}.rating-bubble[data-v-4b710281]:after{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 0 0 no-repeat;bottom:-.1em;content:\"\";display:block;height:.75em;left:-.15em;position:absolute;width:1.3125em;z-index:-1}.rating-bubble.user-rating[data-v-4b710281]{background:#0a9c2a}.rating-bubble.user-rating[data-v-4b710281]:after{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") 0 0 no-repeat}.rating-bubble[data-v-4b710281]:before{bottom:-1.25em;color:var(--colour-text-light);content:attr(data-rating-count);font-size:var(--small);left:auto;position:absolute;right:auto;text-align:center;top:auto}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
