import { render, staticRenderFns } from "./DatesWizardSummaryDesktop.vue?vue&type=template&id=5a3ef89a&scoped=true"
import script from "./DatesWizardSummaryDesktop.vue?vue&type=script&lang=ts"
export * from "./DatesWizardSummaryDesktop.vue?vue&type=script&lang=ts"
import style0 from "./DatesWizardSummaryDesktop.vue?vue&type=style&index=0&id=5a3ef89a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a3ef89a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SearchSummaryBarDetailsCalendarSameMonth: require('/app/components/search/summary-bar/SearchSummaryBarDetailsCalendarSameMonth.vue').default,PuUtcDate: require('/app/components/ui/PuUtcDate.vue').default,Translate: require('/app/components/Translate.vue').default})
