
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'
import { daysBetween } from '~/utility/date/relative'

@Component
export default class DatesWizardBodyHeaderRange extends Vue {
  @Prop({ required: true })
    start: string | null

  @Prop({ required: true })
    end: string | null

  get noDates(): boolean {
    return !this.start && !this.end
  }

  get nights(): number {
    if (!this.start || !this.end) {
      return 0
    }
    return daysBetween(this.start, this.end)
  }
}
