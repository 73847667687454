import { render, staticRenderFns } from "./DatesWizardBody.vue?vue&type=template&id=4ceb84c4&scoped=true"
import script from "./DatesWizardBody.vue?vue&type=script&lang=ts"
export * from "./DatesWizardBody.vue?vue&type=script&lang=ts"
import style0 from "./DatesWizardBody.vue?vue&type=style&index=0&id=4ceb84c4&prod&scoped=true&lang=css"
import style1 from "./DatesWizardBody.vue?vue&type=style&index=1&id=4ceb84c4&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ceb84c4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DatesWizardBodyHeaderRange: require('/app/components/datepickers/wizard/DatesWizardBodyHeaderRange.vue').default,DatesWizardBodyClearButton: require('/app/components/datepickers/wizard/DatesWizardBodyClearButton.vue').default})
